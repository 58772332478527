"use strict";

import gallerySlider from "./modules/gallerySlider.js";
import advantagesSlider from "./modules/advantagesSlider.js";
import hamburger from "./modules/hamburger.js";

try {
    gallerySlider();
} catch (error) {
    
}

try {
    advantagesSlider();
} catch (error) {
    
}

hamburger();